.hocProject {
  width: 27rem;
  height: 34rem;
  padding: 2rem 1rem 0.5rem 1rem;
  margin: 2rem;

  background-color: #cccccc;
  color: #090202;
  border-radius: 5%;

  transition: all 0.2s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.5rem 0.5rem 2rem rgba(grey, 0.3);

  &:hover {
    box-shadow: 1.5rem 1.5rem 3rem rgba(grey, 0.3);
  }

  &__top {
    &--new {
      width: 3rem;
      margin-top: -1rem;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 900;

      background-image: linear-gradient(to left, yellow, orange, red);
      color: black;
    }

    &--header {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    &--description {
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 3rem;
      height: 3rem;

      margin: 2rem 1rem 2rem 0;
      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        fill: darkslategrey;
        transform: translateY(-0.7rem);
      }
    }
  }
}

@media screen and (max-width: 462px) {
  .hocProject {
    width: 70vw;
    height: 40rem;

    padding: 3rem 2rem 1rem 2rem;

    &__top {
      &--header {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 2rem;
      }

      &--description {
        font-size: 1.9rem;
      }
    }

    &__bottom {
      svg {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}

@media screen and (max-width: 462px) {
  .hocProject {
    width: 80vw;
    height: 42rem;
  }
}
