.footer {
  text-align: center;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &__header {
    font-size: 5rem;
    font-weight: 700;

    padding: 1rem;
    margin: 2rem auto;
    text-align: center;
  }

  &__p {
    font-size: 1.6rem;
    width: 50vw;

    margin-top: -5rem;
  }

  &__hello {
    color: inherit;
    font-size: 2.8rem;
    transition: all 0.3s;

    &:hover {
      color: #eeeeee;
      text-decoration: none;
      transform: scale(1.2);
    }
  }

  &__link {
    text-decoration: none;
    margin: 1.5rem;

    &--logo {
      width: 2.5rem;
      height: 2.5rem;
      color: #787a91;


      &:hover {
        color: #eeeeee;
      }
    }
  }

  &__copyright {
    font-size: 1.2rem;
    font-weight: 800;
    margin-top: 1.2rem;

    font-family: sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    min-height: 100vh;

    &__p {
      width: 70vw;
    }
  }
}
