.blogs {
  margin: 1rem 5rem;
  min-height: 100vh;

  &__h1 {
    font-size: 5rem;
    font-weight: 700;

    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 5rem;
    justify-items: center;
  }

  &__end {
    padding: 3rem 0;
    text-align: center;
  }
}
