@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;0,900;1,900&display=swap");

$background: #112240;
$header: whitesmoke;
$text: #ccd6f6;
$white: white;

html {
  font-size: 62.5%;
}

.App {
  background-color: $background;
  color: $text;
  font-family: "Nunito", sans-serif;
  font-size: 2rem;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
  }
}

@media only screen and (max-width: 1100px) {
  html {
    font-size: 58.5%;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 54.5%;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 50.5%;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 44.5%;
  }
}
