.home {
  min-height: 100vh;
  margin: 0 5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  &__left {
    margin: 0 1rem;

    width: 50%;

    display: flex;
    flex-direction: column;

    &--h1 {
      font-size: 3.2rem;
      font-weight: 700;

      padding: 1rem 0;
    }

    &--span {
      color: orangered;
    }

    &--h4 {
      font-size: 2.2rem;

      padding: 1rem 0;
    }

    &--p {
      font-size: 1.6rem;

      padding: 1rem 0;
    }
  }

  &__right {
    margin: 0 1rem;
    transition: all 0.3s;

    display: flex;
    flex-direction: column;

    &--img {
      width: 40rem;
      height: 30rem;

      margin-left: 3rem;
      border-radius: 5%;

      object-fit: cover;

      box-shadow: 2rem 2rem 3rem rgba(grey, 0.3);
    }
  }
}

@media screen and (max-width: 700px) {
  .home {
    flex-direction: column-reverse;

    &__left {
      width: 75vw;
    }

    &__right {
      &--img {
        width: 80vw;
        height: auto;
        margin-left: 0;
        margin-bottom: 5rem;
      }
    }
  }
}
