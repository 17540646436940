@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;0,900;1,900&display=swap);
body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
html{font-size:62.5%}.App{background-color:#112240;color:#ccd6f6;font-family:"Nunito",sans-serif;font-size:2rem;display:flex;flex-direction:column}.App__content{flex:1 1}@media only screen and (max-width: 1100px){html{font-size:58.5%}}@media only screen and (max-width: 1000px){html{font-size:54.5%}}@media only screen and (max-width: 900px){html{font-size:50.5%}}@media only screen and (max-width: 800px){html{font-size:44.5%}}
.footer{text-align:center;min-height:100vh;display:flex;flex-direction:column;justify-content:space-around;align-items:center}.footer__header{font-size:5rem;font-weight:700;padding:1rem;margin:2rem auto;text-align:center}.footer__p{font-size:1.6rem;width:50vw;margin-top:-5rem}.footer__hello{color:inherit;font-size:2.8rem;transition:all .3s}.footer__hello:hover{color:#eee;text-decoration:none;-webkit-transform:scale(1.2);transform:scale(1.2)}.footer__link{text-decoration:none;margin:1.5rem}.footer__link--logo{width:2.5rem;height:2.5rem;color:#787a91}.footer__link--logo:hover{color:#eee}.footer__copyright{font-size:1.2rem;font-weight:800;margin-top:1.2rem;font-family:sans-serif}@media screen and (max-width: 500px){.footer{min-height:100vh}.footer__p{width:70vw}}
.home{min-height:100vh;margin:0 5rem;display:flex;justify-content:center;align-items:center}.home__left{margin:0 1rem;width:50%;display:flex;flex-direction:column}.home__left--h1{font-size:3.2rem;font-weight:700;padding:1rem 0}.home__left--span{color:#ff4500}.home__left--h4{font-size:2.2rem;padding:1rem 0}.home__left--p{font-size:1.6rem;padding:1rem 0}.home__right{margin:0 1rem;transition:all .3s;display:flex;flex-direction:column}.home__right--img{width:40rem;height:30rem;margin-left:3rem;border-radius:5%;object-fit:cover;box-shadow:2rem 2rem 3rem rgba(128,128,128,.3)}@media screen and (max-width: 700px){.home{flex-direction:column-reverse}.home__left{width:75vw}.home__right--img{width:80vw;height:auto;margin-left:0;margin-bottom:5rem}}
.hocProject{width:27rem;height:34rem;padding:2rem 1rem .5rem 1rem;margin:2rem;background-color:#ccc;color:#090202;border-radius:5%;transition:all .2s;display:flex;flex-direction:column;justify-content:space-between;box-shadow:.5rem .5rem 2rem rgba(128,128,128,.3)}.hocProject:hover{box-shadow:1.5rem 1.5rem 3rem rgba(128,128,128,.3)}.hocProject__top--new{width:3rem;margin-top:-1rem;padding:0;font-size:1.2rem;font-weight:900;background-image:linear-gradient(to left, yellow, orange, red);color:#000}.hocProject__top--header{font-size:2rem;text-transform:uppercase;font-weight:800;margin-bottom:1rem}.hocProject__top--description{font-size:1.6rem;line-height:1.3}.hocProject__bottom{display:flex;justify-content:flex-end}.hocProject__bottom svg{width:3rem;height:3rem;margin:2rem 1rem 2rem 0;transition:all .2s}.hocProject__bottom svg:hover{cursor:pointer;fill:#2f4f4f;-webkit-transform:translateY(-0.7rem);transform:translateY(-0.7rem)}@media screen and (max-width: 462px){.hocProject{width:70vw;height:40rem;padding:3rem 2rem 1rem 2rem}.hocProject__top--header{font-size:2.4rem;text-transform:uppercase;font-weight:800;margin-bottom:2rem}.hocProject__top--description{font-size:1.9rem}.hocProject__bottom svg{width:4rem;height:4rem}}@media screen and (max-width: 462px){.hocProject{width:80vw;height:42rem}}
.project{margin:1rem 5rem;min-height:100vh}.project__h1{font-size:5rem;font-weight:700;padding:1rem;margin:1rem auto;text-align:center}.project__content{display:grid;grid-template-columns:repeat(auto-fit, minmax(25rem, 1fr));grid-gap:5rem;justify-items:center}
.hocBlogs{width:27rem;height:33rem;padding:2rem 1rem .5rem 1rem;margin:2rem;background-color:#ccc;color:#090202;border-radius:5%;transition:all .2s;display:flex;flex-direction:column;justify-content:space-between;box-shadow:.5rem .5rem 2rem rgba(128,128,128,.3)}.hocBlogs:hover{box-shadow:1.5rem 1.5rem 3rem rgba(128,128,128,.3)}.hocBlogs__top--header{font-size:2rem;text-transform:uppercase;font-weight:800;margin-bottom:1rem}.hocBlogs__top--description{font-size:1.6rem;line-height:1.3}.hocBlogs__bottom{display:flex;justify-content:center}.hocBlogs__bottom--a{color:#090202;cursor:pointer;transition:all .3s}.hocBlogs__bottom--a svg{width:3rem;height:3rem;margin:2rem 1rem 2rem 0;fill:currentColor}.hocBlogs__bottom--a:hover{color:rgba(0,0,0,.336);text-decoration:none;-webkit-transform:translateY(-5px);transform:translateY(-5px)}@media screen and (max-width: 462px){.hocBlogs{width:70vw;height:40rem;padding:3rem 2rem 1rem 2rem}.hocBlogs__top--header{font-size:2.4rem;text-transform:uppercase;font-weight:800;margin-bottom:2rem}.hocBlogs__top--description{font-size:1.9rem}.hocBlogs__bottom svg{width:4rem;height:4rem}}@media screen and (max-width: 462px){.hocBlogs{width:70vw;height:42rem}}
.blogs{margin:1rem 5rem;min-height:100vh}.blogs__h1{font-size:5rem;font-weight:700;padding:1rem;margin:1rem auto;text-align:center}.blogs__content{display:grid;grid-template-columns:repeat(auto-fit, minmax(25rem, 1fr));grid-gap:5rem;justify-items:center}.blogs__end{padding:3rem 0;text-align:center}
.wallOfFame{margin:1rem 5rem;min-height:100vh;display:flex;flex-direction:column;align-items:center}.wallOfFame__h1{font-size:5rem;font-weight:700;padding:1rem;margin:1rem auto;text-align:center}.wallOfFame__tv{position:relative;width:50rem;height:30rem;margin:2rem auto;background-color:#add8e6;border:1rem solid #000;border-radius:7px;background-image:linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red)}.wallOfFame__tv img{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.wallOfFame__btn{border-radius:3rem;width:4rem;height:4rem;margin:2rem;border:none}.wallOfFame__content{margin-top:5rem;display:grid;grid-template-columns:repeat(auto-fit, minmax(30rem, 1fr));grid-gap:5rem;justify-items:center}.wallOfFame__content img{border:2px solid green;padding:5px}
