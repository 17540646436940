.wallOfFame {
  margin: 1rem 5rem;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__h1 {
    font-size: 5rem;
    font-weight: 700;

    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
  }

  &__tv {
    position: relative;
    width: 50rem;
    height: 30rem;
    margin: 2rem auto;

    background-color: lightblue;
    border: 1rem solid black;
    border-radius: 7px;
    background-image: linear-gradient(
      to left,
      violet,
      indigo,
      blue,
      green,
      yellow,
      orange,
      red
    );

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__btn {
    border-radius: 3rem;
    width: 4rem;
    height: 4rem;
    margin: 2rem;

    border: none;
  }

  &__content {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: 5rem;
    justify-items: center;

    img {
      border: 2px solid green;
      padding: 5px;
    }
  }
}
